import Vue from 'vue'
import App from './App.vue'
import store from './store'
import './plugins/bootstrap-vue'
import VueHotkey from 'v-hotkey'

import dictaVueComponents from 'dicta-vue-components'

Vue.use(dictaVueComponents, {hebrew: true, useBodyClass: true})
Vue.use(VueHotkey)

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
