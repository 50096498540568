import {RunStates} from './runStates'


export const StateSaver = {
  saveState: (stateToSave) => {
    const copyOfStateToSave = Object.assign({}, stateToSave, { version: '1' })
    delete copyOfStateToSave.validationResult
  }
}

export const StateChanges = {
  // ** front end *
  RESET_STATE: 'RESET_STATE',
  SET_ENTIRE_STATE: 'SET_ENTIRE_STATE',
  SET_TEXT_FOR_API_CALL: 'SET_TEXT_FOR_API_CALL',
  SET_TRANSLITERATE_RESULTS: 'SET_TRANSLITERATE_RESULTS',
  SET_REMOVE_NIKUD_RESULTS: 'SET_REMOVE_NIKUD_RESULTS',
 
  // ** concerning APIs *
  TRANSLITERATE_NOT_RUN: 'TRANSLITERATE_NOT_RUN',
  TRANSLITERATE_RUNNING: 'TRANSLITERATE_RUNNING',
  TRANSLITERATE_TIMED_OUT: 'TRANSLITERATE_TIMED_OUT',
  TRANSLITERATE_FAILED: 'TRANSLITERATE_FAILED',
  TRANSLITERATE_COMPLETE: 'TRANSLITERATE_COMPLETE'
}

export const Actions = {
  RUN_TRANSLITERATE: 'RUN_TRANSLITERATE',
  RUN_REMOVE_NIKUD: 'RUN_REMOVE_NIKUD'
}

export const BlankState = {
  apiState: RunStates.NOT_RUN,
  textForApiCall: '',
  apiResponse: null,
  removeNikudApiResponse: null
}
