export const LETTER = {
  ALEF: 'א', BET: 'ב', GIMMEL: 'ג', DALET: 'ד', HEH: 'ה', VAV: 'ו', ZAYIN: 'ז',
  CHET: 'ח', TET: 'ט', YUD: 'י', KAF: 'כ', END_KAF: 'ך', LAMED: 'ל', MEM: 'מ',
  END_MEM: 'ם', NUN: 'נ', END_NUN: 'ן', SAMECH: 'ס', AYIN: 'ע', PEH: 'פ', END_PEH: 'ף',
  TZADI: 'צ', END_TZADI: 'ץ', KUF: 'ק', RESH: 'ר', SHIN: 'ש', TAV: 'ת'
}
export const NIKUD = {
  HATAF_SEGOL: 'ֱ', HATAF_PATAH: 'ֲ', HATAF_QAMATZ: 'ֳ', HIRIQ: 'ִ', TSERE: 'ֵ', SEGOL: 'ֶ',
  PATAH: 'ַ', QAMATZ: 'ָ', SIN_DOT: 'ׂ', SHIN_DOT: 'ׁ', HOLAM: 'ֹ', DAGESH: 'ּ',
  QUBUTZ: 'ֻ', SHEVA: 'ְ', QAMATZ_QATAN: 'ׇ'
}
export const METEG = 'ֽ'
const ALL_NIKUD_REGEX = new RegExp('[' + Object.values(NIKUD).join('') + METEG + ']', 'g')
const ALL_NIKUD_REGEX_METEG = new RegExp('[' + Object.values(NIKUD).join('') + ']', 'g')
const NON_HEBREW_REGEX = new RegExp('[^' + Object.values(LETTER).join('') + Object.values(NIKUD).join('') + ']', 'g')

export function stringWithoutNikud(s) { // note that a meteg is ALSO removed (esp. for user dictionary 'originals')
  if (s)
    return s.replace(ALL_NIKUD_REGEX, '')
}
export function stringWithoutNikudKeepMeteg(s) { // note that a meteg is ALSO removed (esp. for user dictionary 'originals')
  if (s)
    return s.replace(ALL_NIKUD_REGEX_METEG, '')
}

export function stringWithoutMeteggedLetters(s) { // for abbreviation expander (for now)
  return s.replace(new RegExp('[^' + METEG + '](?!' + METEG + ')', 'g'), '')
    .replace(new RegExp('[<>]' + METEG, 'g'), '')
    .replace(new RegExp('(.)' + METEG, 'g'), '$1')
}

export function onlyHebrew(s) {
  return s.replace(NON_HEBREW_REGEX, '')
}

function currentLetterValid(letterArray, currentLetterIndex) {
  return letterArray && (currentLetterIndex !== -1) && letterArray[currentLetterIndex]
}

export function getCurrentLetterSin(letterArray, currentLetterIndex) {
  var sin = false
  if (currentLetterValid(letterArray, currentLetterIndex)) { // for computed properties defined before populating
    sin = letterArray[currentLetterIndex].includes(NIKUD.SIN_DOT)
  }
  return sin
}


export function getCurrentLetterDagesh(letterArray, currentLetterIndex) {
  var hasDagesh = false
  if (currentLetterValid(letterArray, currentLetterIndex)) { // for computed properties defined before populating
    hasDagesh = letterArray[currentLetterIndex].includes(NIKUD.DAGESH)
  }
  return hasDagesh
}

const VAV_WITH_HOLAM_OR_HATAF_ARRAY = [NIKUD.HOLAM, NIKUD.HATAF_PATAH, NIKUD.HATAF_QAMATZ, NIKUD.HATAF_SEGOL].map(
  n => LETTER.VAV + n
)

export function getLetterWithReversedDageshFromLetterArray(letterArray, currentLetterIndex) {
  var original = letterArray[currentLetterIndex]
  var indexInLetterForDagesh = (getCurrentLetterSin(letterArray, currentLetterIndex)
    || (original.includes(NIKUD.SHIN_DOT))) ? 2 : 1
  return getCurrentLetterDagesh(letterArray, currentLetterIndex)
    ? original.replace(NIKUD.DAGESH, '')
    : (VAV_WITH_HOLAM_OR_HATAF_ARRAY.includes(letterArray[currentLetterIndex]))
      ? LETTER.VAV + NIKUD.DAGESH // no dagesh WITH cholam/chataf on vav
      : original.slice(0, indexInLetterForDagesh) + NIKUD.DAGESH + original.slice(indexInLetterForDagesh)
}
