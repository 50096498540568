import { StateChanges } from './stateChanges'
import { RunStates } from './runStates'
function gtag() { window.dataLayer.push(arguments) }

const basicMutations = {
  [StateChanges.SET_ENTIRE_STATE](state, commitObj) {
    this.replaceState(JSON.parse(commitObj.newStateJson))
  },
  [StateChanges.TRANSLITERATE_NOT_RUN]: function (state) {
    state.apiState = RunStates.NOT_RUN
  },
  [StateChanges.TRANSLITERATE_RUNNING](state) {
    state.apiState = RunStates.RUNNING
  },
  [StateChanges.TRANSLITERATE_FAILED]: function (state) {
    state.apiState = RunStates.FAILED
  },
  [StateChanges.TRANSLITERATE_TIMED_OUT]: function (state) {
    state.apiState = RunStates.TIMED_OUT
  },
  [StateChanges.TRANSLITERATE_COMPLETE]: function (state) {
    state.apiState = RunStates.COMPLETE
  },
  [StateChanges.SET_TEXT_FOR_API_CALL]: function (state, text) {
    gtag('event', 'Text Entered', {
      'event_category': 'selection',
      'event_label': text
    })
    state.textForApiCall = text
  },
  [StateChanges.SET_TRANSLITERATE_RESULTS]: function (state, data) {
    state.apiResponse = data
  },
  [StateChanges.SET_REMOVE_NIKUD_RESULTS]: function (state, data) {
    state.removeNikudApiResponse = data
  },
  [StateChanges.RESET_STATE]: function () {

  }

}

export default basicMutations
