import backendRedirect from '../backendRedirect'

const SERVER_ROOT = '/api'
const SERVER = backendRedirect.apiTrafficManager + SERVER_ROOT
const REMOVE_SERVER = backendRedirect.removeNikudApiTrafficManager + SERVER_ROOT

export const AppConfig = {
  SERVER: SERVER,
  REMOVE_SERVER: REMOVE_SERVER
}
