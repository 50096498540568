<template>
  <div class="text-edit pt-1" 
    :class="{'h-mobile-auto':results}" 
    @click="closeMakafPopup" 
    v-hotkey="keymap">
    <div class="container h-100">
      <spinner v-if="loading"/>
      <server-failed-popup v-if="failed" v-model="failed" />
      <div class="row no-gutters my-2 my-md-4 wrap position-relative h-100" ref="textWrap">
        <div class="col-lg-6 pb-5 bg-white rounded-left rounded-right h-100">
          <b-form-group
             :label="hebrew ? 'טקסט מקורי' : 'Original Text'"
              label-for="nikud-text"
              label-class="text-muted mt-0 mb-0 px-3 py-1 rounded-right bg-white position-relative"
              label-size="md"             
              class="m-1 h-100">
              <b-form-textarea
                v-model="nikudString"
                placeholder="הזן טקסט כאן"
                class="f-narkis border-0 h-100 px-3"                      
                id="nikud-text"
                ref="mainText"
                autocomplete="off"           
                @input="onInput"       
            > </b-form-textarea> 
            <b-btn
              v-if="nikudString !== ''"
              class="clear-btn bg-transparent border-0 position-absolute shadow-none"
              @click="clear"
            >
            &times;
          </b-btn>             
          </b-form-group>                   
        </div>
        <div 
          :class="{'d-none':!results}"
          class="col-lg-6 bg-background border-right pb-5 rounded-left h-100 d-md-block">         
          <div class="position-relative px-3 py-1 text-muted rounded-left">
            <span v-if="toolName==='standardize'">
              {{hebrew ? 'טקסט מתוקן' : 'Standardized results'}}
            </span>
            <span v-else-if="toolName==='remove-nikud'">
              {{hebrew ? 'טקסט ללא ניקוד' : 'Text without nikud'}}
            </span>
            <span v-else>
              {{hebrew ? 'תעתוק' : 'Transliterated text'}}
            </span>          
          </div>
          <div 
            class="results-content f-narkis px-3 h-100"
            :dir="toolName !== 'transliterate' ? 'rtl' : 'ltr'"
            :class="{'text-left' : toolName === 'transliterate'}"
          >
            <span class="pb-md-5" v-html="results" v-if="!loading"></span>
          </div>         
        </div>
        <div 
          :class="{'d-none':!results}"
          class="toolbar position-absolute w-100 d-md-block">
          <div class="row no-gutters">
            <div class="col-sm-6 d-none d-md-block">
              <form ref="fileForm">
                <label for="file-input" class="file-button btn btn-secondary px-2 py-0 m-2">
                  <i-file></i-file> {{this.hebrew ? 'העלה קובץ' : 'Upload file'}}
                </label>
                <input 
                  type="file" name="name"
                  accept=".txt, .doc, .docx"
                  id="file-input" class="d-none" ref="inputEl" 
                  @change="loadInputtedFile">
              </form> 
            </div>
            <div class="col-sm-6 border-right px-2">
              <b-btn
                @click="copyResults"
                v-if="results"
                variant="link"
                v-b-tooltip.hover.bottom="{customClass:'mt-n1'}" :title="hebrew ? '(Ctrl-C) העתק' : 'Copy (Ctrl+C)'"
                class="btn-secondary copy-btn toolbar-btn rounded p-0 shadow-none"
              > 
                <i-clone />         
              </b-btn>
              <b-form-checkbox 
                v-if="toolName==='remove-nikud'" 
                v-model="maleify"
                id="add-eimot-kriah-checkbox"
                @change="checkResults"
                class="d-inline position-relative mx-2"
                style="top: 9px;"                
                >
                <span class="d-none d-md-block">{{hebrew ? 'הוסף אימות קריאה' : 'Maleify' }}</span>
                <span class="d-block d-md-none">{{hebrew ? 'אימות קריאה' : 'Maleify' }}</span>
              </b-form-checkbox>
              <b-btn
                id="nothing-btn"
                v-if="containsTemaim()"
                class="btn-secondary toolbar-btn rounded shadow-none p-0 position-relative"
                :class="{'t-selected' : removeTeamim===REMOVE_TEAMIM.REMOVE}" 
                @click="setTeamim(REMOVE_TEAMIM.REMOVE)"
                >
                <h2
                  :class="{'text-primary' : removeTeamim===REMOVE_TEAMIM.REMOVE}" 
                  class="f-narkis mb-0 position-relative"
                  style="top: -3px;"
                  >
                  א
                </h2>
              </b-btn>
              <b-btn
                id="taamim-btn"
                v-if="containsTemaim()"
                class="btn-secondary toolbar-btn rounded shadow-none p-0"
                :class="{'t-selected' : removeTeamim===REMOVE_TEAMIM.KEEP}" 
                @click="setTeamim(REMOVE_TEAMIM.KEEP)"
                >
                <h2
                  :class="{'text-primary' : removeTeamim===REMOVE_TEAMIM.KEEP}" 
                  class="f-narkis mb-0 position-relative"
                  style="top: -3px;"
                  >
                <!-- מ֪ --> 
                א
                <span 
                  style="top:-9px;left: 14px;"
                  class="position-absolute">
                    <b style="font-size:40px;">{{"ב֑".replace('ב','')}}</b>
                  </span>
                </h2>
              </b-btn>
              <b-btn
                id="nikud-btn"
                v-if="containsTemaim() && containsNikud()"
                class="btn-secondary toolbar-btn rounded shadow-none p-0"
                :class="{'t-selected' : removeTeamim===REMOVE_TEAMIM.KEEPNIKUD}" 
               @click="setTeamim(REMOVE_TEAMIM.KEEPNIKUD)"
                >
                <h2
                  :class="{'text-primary' : removeTeamim===REMOVE_TEAMIM.KEEPNIKUD}" 
                  class="f-narkis mb-0 position-relative"
                  style="top: -3px;"
                  >
                  אָ
                </h2>
              </b-btn>                                       
              <b-btn
                id="makaf-menu-btn"
                v-if="containsMakaf()"
                class="btn-secondary toolbar-btn rounded shadow-none p-0 position-relative"
                :class="{'selected':showMakafPopup}"
                @click.stop="showMakafPopup=!showMakafPopup"
              >
                <h2 
                  class="f-narkis mb-0 position-relative"
                  style="top: -2px;"
                >
                  <span>א</span>  
                  <span class="position-relative" style="top:4px;">־</span>                 
                </h2>
              </b-btn>
              <div class="position-absolute makaf-popup bg-white rounded" v-if="showMakafPopup">
                <b-form-group class="mb-0 px-2">
                  <b-form-radio @change="makafEvt('Keep original')" v-model="selectedMakaf" name="text-makaf" class="border-bottom py-1" :value="MAKAF_REPLACE.NONE">השאר את המקף המקורי</b-form-radio>
                  <b-form-radio @change="makafEvt('Replace with dash')" v-model="selectedMakaf" name="text-makaf" class="border-bottom py-1" :value="MAKAF_REPLACE.DASH">החלף את המקף בסימן מינוס</b-form-radio>                  
                  <b-form-radio @change="makafEvt('Replace with space')" v-model="selectedMakaf" name="text-makaf" class="py-1" :value="MAKAF_REPLACE.SPACE">החלף את המקף ברווח</b-form-radio>                  
                </b-form-group>
              </div>
            </div>
          </div>  
        </div>
        <small v-if="showError" class="pt-2">יש להזין טקסט מנוקד, כדי לקבל טקסט ללא ניקוד</small>     
      </div>      
    </div>
    <div 
      id="text-copied-message" 
      class="notify-msg text-white text-center p-3 position-fixed"
      style="display:none;">
     הטקסט הועתק בהצלחה
    </div>
    
  </div>
</template>

<script>
import {stringWithoutNikud, stringWithoutNikudKeepMeteg} from '@/js/commonHebrew' //NIKUD , onlyHebrew
import {Actions} from '@/store/stateChanges'
import {RunStates} from '@/store/runStates'
import openXml from 'openxml'
import base64js from 'base64-js'
import invalidChars from "./lookup.json"
import Spinner from './spinner.vue'
import ServerFailedPopup from './ServerFailedPopup.vue'
function gtag() { window.dataLayer.push(arguments) }
var READABLE_TYPES = {
  text: 'text/plain',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
}
export default {
  name: 'TextEdit',
  components: {
    Spinner,
    ServerFailedPopup
  },
  data() {
    return {
      response: '',
      nikudString: '',
      showError: false,
      MAKAF_REPLACE : {
        NONE: 0,
        SPACE: 1,
        DASH: 2
      },
      REMOVE_TEAMIM: {
        REMOVE: 0,
        KEEP: 1,
        KEEPNIKUD: 2
      },
      removeTeamim: 0,
      selectedMakaf: 0,
      showMakafPopup: false,      
      maleify: false,
      toolName: process.env.VUE_APP_TOOL_NAME //standardize/remove-nikud/transliterate
    }
  },
  mounted () {
    this.$refs.mainText.focus()
  },
  methods: {
    clear () {
      this.$store.commit('SET_REMOVE_NIKUD_RESULTS', '')
      this.$store.commit('SET_TRANSLITERATE_RESULTS', '')
      this.nikudString = ''
      this.showError = false
    },
    standardizeNikud () {      
      if (this.nikudString !== '') {
      let strSplit = this.nikudString.split(' ')
        strSplit.forEach ((word, i) => {
          if(word.length > 0) {
            let wordSplit = word.split(/(?=[^ְֱֲֳִֵֶַָׇֹֻּׁׂ|])/)//word.split(/(?=[א-ת])/)
            wordSplit.forEach ((letter, k) => {    
              let letters = letter.split('')             
              letters.forEach ((ch, j) => { 
                if(invalidChars[ch]) {
                  ch = invalidChars[ch]
                  letters[j] = ch                   
                }  
              })
              wordSplit[k] = letters.join('')
             /*  if (letter.length > 1) {                
                let sortedLetter = this.sortLetters(letter)
                wordSplit[j] = sortedLetter
              } */
            })
            strSplit[i] = wordSplit.join('')         
          }
        })
        return strSplit.join(' ')
       }
       return '' 
    },
    makafEvt (val) {      
      gtag('event', 'Makaf = '+ val)
    },
    setTeamim (val) {
      this.removeTeamim = val
       gtag('event', 'Teamim = '+ val)
    },
    closeMakafPopup (event) {
      if (this.showMakafPopup) {
        let classes = event.target.parentElement.classList
        if(!classes.contains('custom-radio') && !classes.contains('bv-no-focus-ring')) {
          this.showMakafPopup = false
        }
      }
    },
    containsTemaim () {
      return /[\u0591-\u05AF]/.test(this.nikudString) && this.toolName === 'remove-nikud'
    },
    containsMakaf () {
      return /[\u05Be]/.test(this.nikudString) && this.toolName === 'remove-nikud'
    },
    containsNikud () {
      return stringWithoutNikud(this.nikudString) !== this.nikudString
    },
    getCharactersUnicodeValue(characters) {
      characters.forEach(character => {
        console.log(character.charCodeAt(character.indexOf(character)))
      });
    },
    addBorder () {
      //this.$refs.textWrap.classList.add('border-primary')
    },
    checkResults () {
      gtag('event', 'Maleify = '+ this.maleify)
      if (this.maleify) {
        this.onInput()
      }
    },
    onInput () {
      this.showError = false
      if (this.toolName === 'remove-nikud') {
        this.validateText()
      }
      this.$store.commit('SET_TEXT_FOR_API_CALL', this.nikudString)
      if (this.toolName === 'transliterate') {        
        this.$store.dispatch(Actions.RUN_TRANSLITERATE)
      } else if (this.toolName === 'remove-nikud' && this.maleify) {
        this.$store.dispatch(Actions.RUN_REMOVE_NIKUD)
      }
    },
    validateText () {          
      //Check if string doesn't have nikud or not Hebrew
      if (stringWithoutNikud(this.nikudString) === this.nikudString && this.nikudString !== '') // || onlyHebrew(this.nikudString).length == 0)
        this.showError = true
    },
    copyResults () {
      gtag('event', 'Copy Results')
      let textToCopy = document.getSelection().toString() !== ''  ? document.getSelection().toString() : this.results     
      document.getElementById('nikud-text').disabled = true
      document.activeElement.blur()
      const el = document.createElement('textarea')
      el.id = "copyText"
      document.body.appendChild(el)
      document.getElementById('copyText').value = textToCopy.replace(/<br ?\/?>/g, "\n").replace(/<\/?[^>]+(>|$)/g, "")
      document.activeElement.blur()
      document.getElementById('copyText').focus()
      document.getElementById('copyText').select()
      document.execCommand('copy')
      document.body.removeChild(el)
      document.getElementById('nikud-text').disabled = false
      document.getElementById('text-copied-message').style.display = 'block'
      setTimeout(() => { document.getElementById('text-copied-message').style.display = 'none' }, 2000)        
    },
    sortLetters(str) {
      return [...str].sort().reverse().join("")
    },
    loadWordDocFromArrayBuffer (contents) {
      const doc = new openXml.OpenXmlPackage(contents)
      const encoded = base64js.fromByteArray(new Uint8Array(contents))
      const source = {
        fileBase64Contents: encoded,
        type: 'docx',
        runArray: doc.mainDocumentPart().getXDocument().descendants(openXml.W.r).toArray().map(o => o.value),
        wordToRunAndPosMap: []
      }
      const docText = doc.mainDocumentPart().getXDocument().descendants(openXml.W.p).toJoinedString('\n', x => x.value)
      return { source: source, text: docText }
    },    
    loadInputtedFile () {
      var f = this.$refs.inputEl.files[0]   
      // if there is a file available and it is a type we support
      if (f && Object.values(READABLE_TYPES).includes(f.type)) {
        // set up a FileReader, which reads files asynchronously. It fires an onload event when the file is read.
        var r = new FileReader()
        r.onload = (e) => {
          var contents = e.target.result
          var docText = contents
          if (f.type === READABLE_TYPES.docx) {         
            const results = this.loadWordDocFromArrayBuffer(contents)
            docText = results.text
          } 
          // saves information about the source file
          // updates the text in the store - this is a separate commit because it also applies to direct text entry, not just files
          this.nikudString = docText
          this.$refs.fileForm.reset()
          this.onInput()
        }
        // actually start the file read, which will eventually fire the onload event
        // readAsArrayBuffer is used for Word files
        if (f.type === READABLE_TYPES.text) { r.readAsText(f) } else r.readAsArrayBuffer(f)
      } else { 
        alert("Failed to load file")
      }
    }    
  },
  computed: {
    keymap() {
      return {
        'ctrl+c': (event) => { 
          event.preventDefault()
          this.copyResults()
        },
        'meta+c': (event) => {
          event.preventDefault()
          this.copyResults()
        },        
      }
    },    
    results () {
      if(this.toolName==='standardize') {     
        let str = this.standardaizeNikud()
        return str.replace(/\n/g, '<br/>')
      } else if (this.toolName === 'remove-nikud') {
          var  noNikudString = ''
          if (!this.maleify) {
            noNikudString = stringWithoutNikud(this.standardizeNikud().replace(/\n/g, '<br/>'))
          } else {
            if (this.$store.state.removeNikudApiResponse)
              noNikudString = this.$store.state.removeNikudApiResponse.replace(/(.)\u05bd/g,'<span class="text-primary">$1</span>').replace(/\n/g, '<br/>')
          }
          if ( noNikudString) {                  
            if (this.removeTeamim === this.REMOVE_TEAMIM.REMOVE && this.containsTemaim()) {
              noNikudString = noNikudString.replace(/[\u0591-\u05B0]/g, "")
            }
            if (this.removeTeamim === this.REMOVE_TEAMIM.KEEP && this.containsTemaim()) {
              noNikudString = stringWithoutNikudKeepMeteg(this.standardizeNikud().replace(/\n/g, '<br/>'))
            }
            if (this.removeTeamim === this.REMOVE_TEAMIM.KEEPNIKUD) {              
              noNikudString = this.nikudString.replace(/[\u0591-\u05af]/g, "").replace(/\n/g, '<br/>')
            }
            if(this.selectedMakaf == this.MAKAF_REPLACE.SPACE && this.containsMakaf()) {
                noNikudString = noNikudString.replace(/[\u05Be]/g, " ")
            }
            if(this.selectedMakaf == this.MAKAF_REPLACE.DASH) {
                noNikudString = noNikudString.replace(/[\u05Be]/g, "-")
            }
          }
          return noNikudString
      } else {
        if (this.$store.state.apiResponse)
          return this.$store.state.apiResponse.replace(/\n/g, '<br/>')
        else
          return ''
      }     
    },    
    hebrew () {
      return this.$settings.hebrew
    },
    loading () {
      return this.$store.state.apiState === RunStates.RUNNING
    },    
    failed: {
      get () {
        return this.$store.state.apiState === RunStates.FAILED
      },
      set () {
        this.$store.commit('TRANSLITERATE_NOT_RUN')        
      }
    } 
  } 
}
</script>

<style>
  @media (max-width: 991px) {
    html,body,#app {
      height: 100%;
    }
    .tooltip {
      display: none !important;
    }
  }  
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .text-edit {
    height:  calc(100vh - 140px);
    @media (max-width: 991px) {
      min-height:  calc(100% - 65px);
      height:  calc(100% - 65px);
      &.h-mobile-auto {
        height: auto;
      }
    }

    //background:red;
    //overflow-y:hidden;
  }
  .d-header-none {
    .text-edit{
      height:  calc(100vh - 55px);
    }    
  }  
  .wrap {
    border-radius: 6px;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.14);
    border: solid 1px #d8d8d8;
  }
  .rounded-right {
    border-top-right-radius: 6px!important;
    border-bottom-right-radius: 6px!important;   
  }  
  .rounded-left {
    border-top-left-radius: 6px!important;
    border-bottom-left-radius: 6px!important;  
  }
  .border-right {
    border-color: #d8d8d8!important;
  }
  .form-control, .results-content {
    font-size: 25px;
    overflow-y: auto;
    overflow-x: hidden;
    resize: none;
  }
  .clear-btn {
    left: 0;
    font-size: 25px;
    z-index: 10;
    top: 0;
  }
  .notify-msg {      
    right: 24px;
    bottom: 24px;
    width: 238px;
    height: 50px;
    border-radius: 3px;
    background-color: #494949;
    z-index: 9999999;
    @media (max-width: 991px) {
      right: 50%;
      margin-right: -119px;
    }
   }
  .toolbar {
    bottom: 0;
    height: 40px;
    background: #e3e3e3;
    form .file-button {
      font-size: 16px;
    }
    .btn-secondary{
      color: #4a5057;      
      &:hover,&.selected,.t-selected {
        background: #f6f6f6;
        border-color: #f6f6f6;
        cursor: pointer;
        @media (max-width: 991px) {
          background: transparent;
          border-color: transparent
        }        
      }
      &.t-selected {
        background: #d6ecff;
        border-color: #d6ecff;
      }
    
      &.toolbar-btn {  
        h2 {
          line-height: 22px;
        }
        width: 30px;
        height: 30px;
        margin: 6px 3px 0 2px;
        font-size: 16px;
      }
      &.position-relative {
        &::before {
          height: 100%;
          width: 1px;
          background: #d3d3d3;
          content: '';
          position: absolute;
          right: -4px;
          top: 0;
        }
      }
    }
    .makaf-popup {
      top: -88px;
      box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.14);
      border: solid 1px #d8d8d8;
      width: 228px;
      font-size: 14px;
      right: 314px;
      @media (max-width: 991px) {
        right: calc(100% - 228px);
      }
    }
  }
</style>
<style lang="scss">
  .text-edit .bv-no-focus-ring {
    height: calc(100% - 33px);
  }
  #error-modal {
    margin-top: 10%;
    button {
      width: 100%;
    }
  }
</style>