<template>
  <div id="app">
    <Header />
    <!-- <ToggleHeader /> -->
    <text-edit></text-edit>
  </div>
</template>

<script>
import Header from '@/components/header'
//import ToggleHeader from '@/components/ToggleHeader'
import TextEdit from '@/components/TextEdit'

export default {
  name: 'App',
  components: {
    Header,
  //  ToggleHeader,
    TextEdit
  },
  data() {
    return {
    }
  }
}
</script>

<style>
  @font-face {
    font-family: 'mft_narkisclassic';
    src: url('assets/fonts/mft_narkisclassic-regular.woff') format('woff');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: 'mft_narkisclassic';
    src: url('assets/fonts/MFTW_NarkisClassicBold.woff') format('woff');
    font-style: normal;
    font-weight: bold;
  }
  .f-narkis{
    font-family: Roboto, mft_narkisclassic, sans-serif;
    line-height: 1.5;
  }
</style>
