<template>
  <b-modal
    id="mobile-how-modal"
    v-model="show" 
    ok-only 
    centered 
    size="lg" 
    footer-class="border-top d-none d-sm-block"
    header-class="d-none d-sm-block"
    :ok-title="hebrew ? 'סגור' : 'Close'"
    ok-variant="secondary"
    >
    <template slot="modal-header">{{hebrew ? 'איך זה עובד' : 'How it works'}}</template>
    <div class="mobile-modal-top bg-secondary position-fixed w-100 d-block d-sm-none">
      <div class="row">
        <div class="col-8">
          <h3>{{hebrew ? 'איך זה עובד' : 'How it works'}}</h3>
        </div>
        <div @click="show=false" class="close-icon col-4" :class="{'text-left': hebrew, 'text-right': !hebrew}">
          <i class="fas fa-times"></i>
        </div>
      </div>
    </div>    
    <div class="how-to-text" v-if="hebrew && appName === 'remove-nikud'">
      <p>
        הכלי נועד להסיר ניקוד וטעמי המקרא מכל טקסט מנוקד. הזינו טקסט במקום המיועד לכך-בצד ימין וקבלו טקסט חסר ניקוד, בצד שמאל.
      </p>
      <p>ברירת המחדל של הכלי מוגדרת כך שלטקסט המקורי יתווספו אימות קריאה בהתאם לכללי הכתיב המלא. אפשר יהיה להסיר אותן על ידי הסרת הסימן של אימות קריאה בסרגל הכלים.</p>
      <p>
        <b>הזנת טקסט</b><br>
        הדביקו טקסט מנוקד במקום המיועד, או העלו קובץ טקסט באמצעות לחיצה על הכפתור "העלה קובץ".
      </p>
      <p>
        <b>טעמי המקרא</b><br>
        אם העליתם טקסט שכולל טעמי מקרא, תוכלו להגדיר אם להציג את הטעמים באמצעות לחיצה על הסמליל של טעמי המקרא שנמצא בסרגל הכלים.
      </p>
      <p>
        <b>מקף</b><br>
        אם הטקסט שלכם כולל את סימן המקף (כפי שמופיע בטקסטים תנ"כיים), תוכלו להגדיר כיצד אתם מעוניינים לטפל בו, על ידי לחיצה על סמליל המקף שבסרגל הכלים: להשאיר אותו כמות שהוא, להחליף אותו בסימן רווח או להחליף אותו בסימן מינוס.
      </p>
      <p>
        <b>העתקה</b><br>
        בכל שלב אפשר להעתיק את הטקסט ללא הניקוד, באמצעות לחיצה על סמליל ההעתקה שנמצא בסרגל הכלים.
      </p>
    </div>
    <div v-else-if="hebrew && appName === 'transliterate'" class="how-to-text">
      כלי התעתוק נועד ליצור תעתוק לטקסט עברי באותיות לטיניות.<br>
      כדי לקבל תעתוק מדויק, מומלץ להזין טקסט מנוקד. אם הטקסט שלכם אינו מנוקד, אתם מוזמנים להשתמש
       <a href="https://nakdanpro.dicta.org.il/" target="_blank">בנקדן</a> של דיקטה.<br>
      כדי להתחיל הזינו טקסט בתיבה הימנית. אפשר גם להעלות טקסט מתוך קובץ, בלחיצה על הכפתור שנמצא בתחתית התיבה.<br>
      בתיבה השמאלית יוצג התעתוק באותיות לטיניות. כדי להעתיק את הטקסט לחצו על הצלמית (אייקון) של העתקה שנמצא בתחתית התיבה.<br>
      הכלי חינמי.
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "about-dialog",
  props: ["value"],
  data() {
    return {
      appName: process.env.VUE_APP_TOOL_NAME
    }
  },
  computed: {
    hebrew () {
      return this.$settings.hebrew
    }, 
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
}
</script>
<style lang="scss">
  @media screen and (max-width: 767px) {
    #mobile-how-modal{
      -webkit-overflow-scrolling: touch;
      .modal-dialog {
        margin: 0!important;
      }
      .modal-content {
        border-radius: 0!important;
        height: 100vh;
        overflow-y: scroll;
        overflow-x:hidden;
        background:white;
      }
      .mobile-modal-top{
          height: 50px;
          top:0;
          right:0;
          padding:15px;
          z-index: 1;
          h3{text-decoration:none;}
      }
      .how-to-text{
          padding-top:50px;
          max-height: 88vh;
          overflow-y: scroll;
          overflow-x:hidden;
      }
      @media screen and (max-width: 667px) {
        .how-to-text{
          max-height: 83vh;
        }
      }      
    }
  }
</style>
<style scoped lang="scss">
.how-to-text {
  max-height: 70vh;
  overflow: auto;
  h3{
  text-decoration: underline;
  }
}
</style>
