<!-- loosely based on vue-spinner -->
<template>
  <div class="v-spinner" v-show="loading">
    <div class="v-clip"></div>
  </div>
</template>

<script>
export default {
  name: 'spinner',
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style>
  .v-spinner {
    text-align: center;
  }

  .v-spinner .v-clip {
    animation: v-clipDelay 0.75s 0s infinite linear;
    animation-fill-mode: both;
    display: inline-block;
    height: 35px;
    width: 35px;
    border: 2px solid #007ee5;
    border-bottom-color: transparent;
    border-radius: 100%;
    background: transparent;
    position:absolute;
    left: 50%;
    top: 40vh;
    margin-top: -18px;
    margin-left: -18px; 
    z-index: 99;
  }

  @keyframes v-clipDelay {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
