import Vue from 'vue'
import Vuex from 'vuex'
import basicMutations from './store/basicMutations'
import {BlankState} from './store/stateChanges'
import searchActions from './store/searchActions'

Vue.use(Vuex)
const store = new Vuex.Store({
  state: JSON.parse(JSON.stringify(BlankState)),
  mutations: {
    ...basicMutations,
   
  },
  actions: {
    ...searchActions,
  }
})

export default store