<template>
  <header class="header bg-white">
    <dicta-header :englishSupported="false" :showPromotions="'word-search'"></dicta-header>
    <div class="bg-primary position-relative">
      <div class="container second-header">
        <div class="row">
          <div class="col-6">
            <div class="logo  text-white">
              <div class="text-center d-inline-block bg-white rounded-circle" @click="resetData">
                <img v-if="appName==='transliterate'" class="" src="../assets/t-logo.png"  alt="Logo"/>
                <img v-else-if="appName==='remove-nikud'" class="" src="../assets/r-logo.png"  alt="Logo"/>
                <img v-else class="" src="../assets/t-logo.png"  alt="Logo"/>
              </div>  
              <div class="mx-2 d-inline-block">
                <div class="tool-name">{{toolName}}</div>
                <div class="dicta">DICTA</div>
              </div>  
            </div>
          </div>
          <div class="col-6">
            <div class="d-block d-sm-none position-absolute mobile-menu-wrap">
              <mobile-menu @showHowDialog="showAbout=true" :englishSupported="false" :howDialog="true"></mobile-menu>
            </div>  
            <div class="d-none d-sm-block" 
              :class="hebrew ? 'text-left' :  'text-right'">
              <a 
                style="cursor:pointer" 
                class="text-white m-3 border-bottom pt-2 d-none xd-md-inline" 
                @click="showShortcuts = true">
                {{hebrew ? 'קיצורי מקלדת' : 'Keyboard shortcuts'}}
              </a>            
              <b-button variant="primary" class="how-btn btn btn-outline-light btn-primary mt-1" size="md" @click="showAbout = true">
                {{hebrew ? 'איך זה עובד' : 'How it works'}}
              </b-button>
            </div>         
          </div>
        </div>
      </div>
    </div>
    <keyboard-shortcuts v-model="showShortcuts"></keyboard-shortcuts>
    <about-dialog v-model="showAbout"></about-dialog>
  </header>
</template>
<script>
  import AboutDialog from '@/components/HowDialog'
  import KeyboardShortcuts from '@/components/KeyboardShortcuts'
  export default  {
    name: 'Header',
    components: {
      AboutDialog,
      KeyboardShortcuts
    },
    data() {
      return {
        showAbout: false,
        showShortcuts: false,
        appName: process.env.VUE_APP_TOOL_NAME,
        toolName: ''
      }   
    },
    mounted () {
      switch (this.appName) {
        case 'standardize':
          this.toolName = this.hebrew ? 'תיקון ניקוד' : 'Standardize Nikud'
          break;
        case 'remove-nikud':  
          this.toolName = this.hebrew ? 'הסרת ניקוד' : 'Remove Nikud'
          break;
        case 'transliterate':  
          this.toolName = this.hebrew ? 'תעתוק' : 'Transliterate'
          break;          
      }
    },
    methods: {
      resetData () {
        this.$store.commit('RESET_STATE') 
      }
    },
    computed: {
      hebrew () {
        return this.$settings.hebrew
      }
    }     
  }    
</script>
<style>
  .top-bar{
    margin: 0 auto!important;
    padding: 5px 0;
  }
</style>
<style scoped lang="scss">
  .second-header {
    padding-top: 7px;
    padding-bottom: 7px;
    @media (max-width: 767px) {    
      height: 46px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .logo {
    font-size: 18px;
    .d-inline-block {
      vertical-align: top;
    }
    .tool-name {
      line-height: 21px;
      margin-bottom: 4px;
      @media (max-width: 767px) {
        line-height: 29px;
        margin-bottom: -3px;        
      }
    }    
    .dicta {
      font-size: 13px;
      line-height: 13px;
      opacity: .7;
      font-family: 'Arimo';
    }
    .rounded-circle {
      height: 40px;
      width: 40px;
      cursor: pointer;
      img {
        max-height: 100%;
      }
      @media (max-width: 767px) {
        height: 32px;
        width: 32px;
        margin-top: 7px;      
      }
    }    
  }
  .mobile-menu-wrap {
    left: 15px;
    top: 10px;
  }   
  .how-btn {
      margin-top: 3px; 
  }
</style>